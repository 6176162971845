import { Component, OnInit } from '@angular/core';
import { Words } from './words';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public word1 = '';
  public word2 = '';

  ngOnInit(): void {
    this.refresh();
  }

  public refresh(): void {
    this.word1 = Words[Math.floor(Math.random() * 1000)];
    this.word2 = Words[Math.floor(Math.random() * 1000)];
  }
}
